//import "jquery-2.2.4.js" 
//import "jquery-ui.js" 
//import "jquery.ui.touch-punch.min.js" 
//import "modernizr.js" 
//import "bootstrap.js" 
//import "wow.js"   
//import "datalist.js"  
//import "global.js"  
//import "simple-lightbox.min.js"  
//import "events.handlers.js"
